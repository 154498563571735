import { useState, useEffect } from "react";
import './App.css';
import { kinoApi } from "./common/api/kino/api"
import { KinoDTO, MainPromoBannerDTO, ShowedKinoVideoDTO } from "./common/api/kino/types";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { userApi } from "./common/api/user/api";
import { UserInfoResponse } from "./common/api/user/types";

function App() {
  const [isUserInfoLoading, setIsUserInfoLoading] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<UserInfoResponse>()
  const getUserInfo = async () => {
    try {
      setIsUserInfoLoading(true);
      const data = await userApi.getInfo();
      if (data) {
        setUserInfo(data)
      }
      setIsUserInfoLoading(false)
    } catch (e) {
        console.log(e)
        setIsUserInfoLoading(false)
    }
  }
  useEffect(() => {
    getUserInfo().catch();
  }, [])

  const [kinosLimit, setKinosLimit] = useState<number>(3)
  const onChangePopularKinosLimit = () => {
    setKinosLimit(kinosLimit + 3)
  }

  const [isKinosLoading, setIsKinosLoading] = useState<boolean>(false)
  const [kinos, setKinos] = useState<KinoDTO[]>([]);
  const [isKinosOverflow, setIsKinosOvertflow] = useState<boolean>()
  const [mainPromoBanner, setMainPromoBanner] = useState<MainPromoBannerDTO | null>(null);
  const getPopularKinos = async () => {
      try {
          setIsKinosLoading(true);
          const data = await kinoApi.getPopular(0, kinosLimit);
          if (data && data.kinos) {
            setKinos(data.kinos)
          }
          if (data && data.main_promo_banner) {
            setMainPromoBanner(data.main_promo_banner)
          }
          if (data && data.is_kinos_overflow) {
            setIsKinosOvertflow(data.is_kinos_overflow)
          }
          setIsKinosLoading(false)
      } catch (e) {
          console.log(e)
      }
  };
  useEffect(() => {
    getPopularKinos().catch();
  }, [kinosLimit]);

  const [isLastKinosLoading, setIsLastKinosLoading] = useState<boolean>(false);
  const [lastKinos, setLastKinos] = useState<ShowedKinoVideoDTO[]>([]);
  const getLastKinos = async () => {
    try {
        setIsLastKinosLoading(true)
        const data = await kinoApi.getShowedKinos();
        if (data && data.kinos_video) {
          setLastKinos(data.kinos_video)
        }
        setIsLastKinosLoading(false)
    } catch (e) {
        console.log(e)
        setIsLastKinosLoading(false)
    }
  };
  useEffect(() => {
    getLastKinos().catch();
  }, [])

  const onSelectKino = (kinoID: number) => {
    if (window.Telegram.WebApp.initData === "") {
      console.log("Сайт должен быть запущен только как Телеграмм Веб Приложение")
    } else {
      kinoApi.selectKino({kino_id: kinoID}).catch()
      window.Telegram.WebApp.close()
    }
  }

  const [showSearchMenu, setShowSearchMenu] = useState<boolean>(false)
  const [searchTitle, setSearchTitle] = useState<string>()
  const onSearchFocucIn = () => {
    setSearchTitle("Найдите желаемое кино")
    setShowSearchMenu(true)
  }
  const onSearchMenuClose = () => {
    setShowSearchMenu(false)
    setSearchKinos([])
  }

  const [searchKinos, setSearchKinos] = useState<KinoDTO[]>([]);
  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const suggest = async () => {
      try {
          const data = await kinoApi.suggest({query: event.target.value})
          if (data && data.possible) {
            setSearchTitle("Возможно, вы искали")
            setSearchKinos([data.possible])
          }
      } catch (e) {
        console.log(e)
      }
    };
    suggest().catch();
  }

  return (
    <div className="App">
      <div className="on-dark-after-search-menu" style={{'display': showSearchMenu?'block': 'none'}}></div>
      <nav className="navbar navbar-light bg-light pb-3">
        <a className="navbar-brand" href="#">
          <img src={require('./logo.png')} width="30" height="30" className="d-inline-block align-top mr-3" alt=""></img>
          <span>Kinodownload</span>
        </a>
        <input className="rounded form-control mt-2 with-search-icon"
          type="search" placeholder="Поиск" aria-label="Поиск"
          onChange={onSearchChange} onFocus={onSearchFocucIn}
          style={{zIndex: '1000', backgroundColor: '#fff'}}></input>
        <ul className="dropdown-menu" style={{'display': showSearchMenu?'block': 'none', top: '90%', left: '5px', right: '5px'}}>
          <li>
            <div className="container-fluid">
              <div className="row">
              <h6 className="dropdown-header col-10 align-self-center">{searchTitle}</h6>
              <button type="button" className="close col-2" aria-label="Close"><span aria-hidden="true" onClick={onSearchMenuClose}>&times;</span></button>
              </div>
            </div>
          </li>
          {
            searchKinos.map((kino, idx) => {
              return (
                <li key={idx}>
                  <div className="container-fluid dropdown-item">
                    <div className="row">
                      <div className="col-2">
                        {
                            kino.thumbnail && <img style={{width: '42px', height: '62px'}} src={`data:;base64,${kino.thumbnail}`}></img>
                        }
                      </div>
                      <div className="col-6 align-self-center">
                        <div className="card-body p-0">
                          <h6 className="card-title mb-1">{kino.name}</h6>
                          <p className="card-text"><small className="text-muted">{kino.country && <span>{kino.country} | </span>}{kino.tags?.at(0)}</small></p>
                        </div>
                      </div>
                      <div className="col-4 pr-0 align-self-center">
                      <button type="button" className="btn btn-primary" style={{fontSize: '12px', borderRadius: '0'}} onClick={()=>onSelectKino(kino.id)}>Смотреть</button>
                      </div>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      </nav>
      {isKinosLoading && (
        <Skeleton count={1} className="card" style={{height: '13rem'}}/>
      )}
      {mainPromoBanner && !isKinosLoading && (
        <div className="position-relative" style={{backgroundColor: 'black'}}>
          <img style={{width: '100%', opacity: '0.4'}} src={`data:;base64,${mainPromoBanner.image}`}></img>
          <div className="card position-absolute text-center" style={{color: 'white', top: '26%', backgroundColor: 'rgba(0, 0, 0, 0)'}}>
            <h5 className="card-title text-center mb-1">{mainPromoBanner.title}</h5>
            <p className="card-text text-center pl-4 pr-4 mb-3"><small style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: '12px', lineHeight: '0'}}>{mainPromoBanner.description}</small></p>
            <div className="text-center">
              <button type="button" className="btn btn-primary btn-sm" style={{background: 'linear-gradient(135deg, rgba(0,123,255,1) 44%, rgba(0,50,255,0.8618040966386554) 100%)', border: '0', width: '50%'}} onClick={()=>onSelectKino(mainPromoBanner.kino_id)}>Подробнее</button>
            </div>
          </div>
        </div>
      )}
      {
        isLastKinosLoading && (
          <Skeleton count={1} className="card" style={{height: '21rem'}}/>
        )
      }
      {!isLastKinosLoading && lastKinos.length != 0 && (
        <div>
          <h4 className="text-center p-1 mt-3">Вы смотрели</h4>
          <Slider {...{infinite: false, slidesToShow: 1}} className="carousel-inner">
          {
            lastKinos.map((kino, idx) => {
              return (
                <div key={idx} className="carousel-item card" onClick={()=>onSelectKino(kino.kino_id)}>
                  <div className="card-body pr-2 pl-2">
                    <img src={`data:;base64,${kino.video_thumbnail}`} className="w-100" alt="..."></img>
                    <h6 className="card-subtitle pt-3" style={{whiteSpace: 'pre-line'}}>{kino.video_name}</h6>
                  </div>
                </div>
              )
            })
          }
          </Slider>
        </div>
      )}
      <h4 className="text-center p-1 mt-3">Популярное кино</h4>
      {
          <ul className="list-group">
            {isKinosLoading && (
              <Skeleton count={kinosLimit} className="card" style={{height: '9rem'}}/>
            )}
            {
              !isKinosLoading && kinos.map((kino, idx) => {
                return (
                  <li className="card flex-row p-3" key={idx} style={{'justifyContent': 'center', 'alignItems': 'center'}}>
                      {
                          kino.thumbnail && <img className="card-img-top" style={{width: '100px'}} src={`data:;base64,${kino.thumbnail}`}></img>
                      }

                      <div className="card-body p-0 align-self-stretch position-relative pt-1 pl-3">
                      <h6 className="card-title text-muted mb-4" style={{fontSize: '14px'}}>
                          {kino.ratings?.kp && <span style={{padding: '5px', backgroundColor: '#f50', color: 'black', marginRight: '5px'}}>
                          {kino.ratings?.kp}</span>}{kino.ratings?.imdb && <span style={{padding: '5px', backgroundColor: '#f5c518', color: 'black', marginRight: '5px'}}>{kino.ratings.imdb}</span>}
                        </h6>
                        <h6 className="card-subtitle">{kino.name}</h6>
                        <p className="card-text"><small className="text-muted">{kino.country && <span>{kino.country} | </span>}{kino.tags?.at(0)}</small></p>
                        <button type="button" className="btn btn-primary" style={{fontSize: '14px', position: 'absolute', bottom: '0px'}} onClick={()=>onSelectKino(kino.id)}>Смотреть</button>
                      </div>
                  </li>
                )
              })
            }
            {!isKinosOverflow && (
              <li className="p-3 text-center"><button className="btn btn-outline-secondary" style={{width: "30%"}} onClick={onChangePopularKinosLimit}>Еще</button></li>
            )}
          </ul>
      }
      {
        !isUserInfoLoading && !userInfo?.has_sub && (
          <div>
            <h4 className="text-center p-1 mt-3">Возможно, будет интересно</h4>
            <div id="container-3beb590d8e7f63579ecfdbaeb25e4225" style={{fontSize: '16px', color: 'rgb(33, 37, 41)'}}></div>
          </div>
        )
      }
    </div>
  );
}

export default App;
