import { kinoDownloadClient } from '../client';
import { RecomendationsResponse, PopularResponse, SelectKinoRequest, SuggestRequest, SuggestResponse, ShowedKinoVideosResponse } from './types';

export const kinoApi = {
    async getRecomendations(): Promise<RecomendationsResponse> {
        const response = await kinoDownloadClient.get<RecomendationsResponse>(`/recomendations`, {});
        return response.data;
    },
    async getPopular(cursor: number, limit: number): Promise<PopularResponse> {
      const response = await kinoDownloadClient.get<PopularResponse>(`/popular?cursor=${cursor}&limit=${limit}`, {});
      return response.data;
    }, 
    async getShowedKinos(): Promise<ShowedKinoVideosResponse> {
      const response = await kinoDownloadClient.get<ShowedKinoVideosResponse>(`/showed_kinos`, {});
      return response.data;
    },
    async selectKino(req: SelectKinoRequest): Promise<void> {
      const response = await kinoDownloadClient.post<void>(`/select_kino`, {
        ...req
      });
      return response.data;
    },
    async suggest(req: SuggestRequest): Promise<SuggestResponse> {
      const response = await kinoDownloadClient.post<SuggestResponse>(`/suggest`, {
        ...req
      });
      return response.data;
    }
};