import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export type ApiConfig = {
    baseURL?: string;
    isDebug: boolean;
}

export class ApiClient {
    client: AxiosInstance;

    constructor(config: ApiConfig) {
        let initData = "P2vn3P4r5Byp"
        if (!config.isDebug) {
            initData = window.Telegram.WebApp.initData
        }
        this.client = axios.create({
            headers: {
                'Content-Type': 'application/json',
                post: {
                    'Content-Type': 'application/json'
                },
                'InitData': initData
            },
            baseURL: config.baseURL,
        });
    }

    public get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.client.get(url, config);
    }

    public post<T>(url: string, data?: Record<string, unknown>, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.client.post(url, data, config);
    }
}

export const kinoDownloadClient = new ApiClient({ baseURL: `https://kinodownload.ru/api/v1`, isDebug: false });